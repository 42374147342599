export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,3];

export const dictionary = {
		"/api/login": [24],
		"/api/profile": [25],
		"/api/subscribe": [26],
		"/[lang]/(public)": [10,[5]],
		"/[lang]/(app)/booking/edit/[questionnaire_id]": [~6,[2]],
		"/[lang]/(public)/business": [11,[5]],
		"/[lang]/(public)/clinic": [12,[5]],
		"/[lang]/(public)/intro": [13,[5]],
		"/[lang]/(public)/join": [14,[5]],
		"/[lang]/(app)/login": [7,[2]],
		"/[lang]/(public)/manifesto": [15,[5]],
		"/[lang]/(public)/prevention": [16,[5]],
		"/[lang]/(public)/privacy": [17,[5]],
		"/[lang]/(public)/pro": [18,[5]],
		"/[lang]/(app)/questionnaire": [~8,[2]],
		"/[lang]/(app)/questionnaire/[questionnaire_id]/[category]/[question]": [9,[2,3,4]],
		"/[lang]/(public)/services": [19,[5]],
		"/[lang]/(public)/team": [20,[5]],
		"/[lang]/(public)/team/[id]": [21,[5]],
		"/[lang]/(public)/teasing": [22,[5]],
		"/[lang]/(public)/terms": [23,[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';